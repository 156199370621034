define("bottomline-execution/components/geographical-maps/base-map", ["exports", "@ember/component", "@ember/object/proxy", "@ember/object", "@ember/service", "@ember/utils", "@ember/runloop", "bottomline-execution/mixins/app-state"], function (_exports, _component, _proxy, _object, _service, _utils, _runloop, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var FullScreenControl = L.Control.extend({
    options: {
      position: 'topleft'
    },
    onAdd: function onAdd(map) {
      var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');

      this._applyFullscreenIconStyle(container, map.component.get('isFullscreen'));

      container.onclick = function () {
        map.component.toggleProperty('isFullscreen');

        if (map.component.get('isFullscreen')) {
          map.component.set('geographicalMapElementId', 'geographical-map-fullscreen');
        } else {
          map.component.set('geographicalMapElementId', 'geographical-map');
        }

        map.component._renderMap();
      };

      return container;
    },
    _applyFullscreenIconStyle: function _applyFullscreenIconStyle(container, isFullscreen) {
      container.style.backgroundImage = "url(assets/fullscreen.svg)";
      container.style.backgroundRepeat = 'no-repeat';
      container.style.backgroundPosition = isFullscreen ? '2px -24px' : '2px 2px';
      container.style.backgroundColor = 'white';
      container.style.width = '30px';
      container.style.height = '30px';
    }
  });

  var _default = _component.default.extend(_appState.default, {
    assetMap: (0, _service.inject)('asset-map'),
    locationService: (0, _service.inject)('location'),
    mapMoveHandler: false,
    visibleIcons: [],
    geographicalMap: null,
    model: null,
    currentLocationIcon: null,
    currentLocationCoordinates: [],
    lastTimeLocationRetrieved: null,
    geographicalMapElementId: 'geographical-map',
    isFullscreen: false,
    currentLocationChanged: (0, _object.observer)('locationService.lat', 'locationService.lng', function () {
      var geographicalMap = this.get('geographicalMap');

      this._renderCurrentLocationIcon(geographicalMap);
    }),
    deviceOrientationChanged: (0, _object.observer)('appState.deviceOrientation', function () {
      var _this = this;

      (0, _runloop.next)(function () {
        _this._applyDefaultZoom();
      });
    }),
    invertedWidthChanged: (0, _object.observer)('invertedWidth', function () {
      var _this2 = this;

      (0, _runloop.next)(function () {
        _this2._applyDefaultZoom();
      });
    }),
    _renderMap: function _renderMap() {
      var _this3 = this;

      this._removeGeographicalMap();

      (0, _runloop.next)(function () {
        var geographicalMap = _this3._createGeographicalMap();

        _this3._renderCurrentLocationIcon(geographicalMap);

        _this3._renderIcons(geographicalMap);

        _this3._applyDefaultZoom();
      });
    },
    _removeGeographicalMap: function _removeGeographicalMap() {
      var geographicalMap = this.get('geographicalMap');

      if ((0, _utils.isPresent)(geographicalMap)) {
        geographicalMap.remove();
        this.set('geographicalMap', null);
      }
    },
    _applyDefaultZoom: function _applyDefaultZoom() {
      var iconsToIncludeInDefaultZoom = this._iconsToShowOnDefaultZoom();

      var bounds = this._defaultMapBounds(iconsToIncludeInDefaultZoom);

      if ((0, _utils.isEmpty)(bounds)) return;
      this.get('geographicalMap').fitBounds(bounds, {
        padding: [25, 25]
      });
    },
    _coordinatesBasedOnIcons: function _coordinatesBasedOnIcons(icons) {
      var iconLatitudes = icons.map(function (icon) {
        return icon.get('latitude');
      });
      var iconLongitudes = icons.map(function (icon) {
        return icon.get('longitude');
      });
      return {
        iconLatitudes: iconLatitudes,
        iconLongitudes: iconLongitudes
      };
    },
    _calculateMapBounds: function _calculateMapBounds(coordinates) {
      var iconLatitudes = coordinates.iconLatitudes;
      var iconLongitudes = coordinates.iconLongitudes;
      var southWestBounds = [Math.max.apply(Math, _toConsumableArray(iconLatitudes)), Math.max.apply(Math, _toConsumableArray(iconLongitudes))];
      var northEastBounds = [Math.min.apply(Math, _toConsumableArray(iconLatitudes)), Math.min.apply(Math, _toConsumableArray(iconLongitudes))];
      if (northEastBounds.includes(Infinity) || southWestBounds.includes(-Infinity)) return [];
      return L.latLngBounds(southWestBounds, northEastBounds);
    },
    _markerForIcons: function _markerForIcons(marker, coordinates) {
      return _proxy.default.create({
        marker: marker,
        latitude: coordinates.lat,
        longitude: coordinates.lng
      });
    },
    _createGeographicalMap: function _createGeographicalMap() {
      var geographicalMap = this.get('geographicalMap');
      if (geographicalMap) return geographicalMap;
      geographicalMap = new L.Map(this.get('geographicalMapElementId'), {
        center: this._getCenterCoordinates(),
        zoom: 10,
        scrollWheelZoom: false,
        layers: new L.TileLayer('https://b.tile.openstreetmap.org/{z}/{x}/{y}.png')
      });
      geographicalMap.component = this;

      this._addControlsForMap(geographicalMap);

      if (this.get('mapMoveHandler')) {
        this._defineMapMoveHandler(geographicalMap);
      }

      this.set('geographicalMap', geographicalMap);
      return geographicalMap;
    },
    _getCenterCoordinates: function _getCenterCoordinates() {
      return [50.117286, 9.247769];
    },
    _addControlsForMap: function _addControlsForMap(geographicalMap) {
      geographicalMap.addControl(new FullScreenControl());
    },
    _renderCurrentLocationIcon: function _renderCurrentLocationIcon(geographicalMap) {
      if (this.isDestroying || this.isDestroyed) return;
      if ((0, _utils.isEmpty)(geographicalMap)) return;

      var currentLocationCoordinates = this._getCurrentLocationCoordinates();

      var currentLocationIcon = this.get('currentLocationIcon');
      if ((0, _utils.isEmpty)(currentLocationCoordinates) && (0, _utils.isEmpty)(currentLocationIcon)) return;

      if ((0, _utils.isEmpty)(currentLocationCoordinates)) {
        this._removeCurrentLocationIcon();

        return;
      }

      if ((0, _utils.isEmpty)(currentLocationIcon)) {
        currentLocationIcon = this._createCurrentLocationIcon(currentLocationCoordinates);
      } else {
        currentLocationIcon.setLatLng(currentLocationCoordinates);
      }

      this.set('currentLocationIcon', currentLocationIcon);
      currentLocationIcon.addTo(geographicalMap).update();
    },
    _removeCurrentLocationIcon: function _removeCurrentLocationIcon() {
      var currentLocationIcon = this.get('currentLocationIcon');
      if ((0, _utils.isPresent)(currentLocationIcon)) currentLocationIcon.remove();
    },
    _getCurrentLocationCoordinates: function _getCurrentLocationCoordinates() {
      var currentLocationCoordinates = [];
      var now = new Date();

      if (this.get('locationService.lat') && this.get('locationService.lng')) {
        currentLocationCoordinates = [this.get('locationService.lat'), this.get('locationService.lng')];
        this.setProperties({
          'currentLocationCoordinates': currentLocationCoordinates,
          'lastTimeLocationRetrieved': now
        });
        return currentLocationCoordinates;
      }

      if (this._isLastKnownCurrentLocationRecent()) {
        return this.get('currentLocationCoordinates');
      }

      return [];
    },
    _createCurrentLocationIcon: function _createCurrentLocationIcon(coordinates) {
      var currentLocationIcon = L.icon({
        iconUrl: 'assets/truck.png'
      });
      return L.marker(coordinates, {
        icon: currentLocationIcon,
        zIndexOffset: 1300
      });
    },
    _isLastKnownCurrentLocationRecent: function _isLastKnownCurrentLocationRecent() {
      var lastTimeLocationRetrieved = this.get('lastTimeLocationRetrieved');
      if ((0, _utils.isEmpty)(lastTimeLocationRetrieved)) return false;
      var tenMinutes = 600; // seconds

      var time = this._getCurrentTimeStamp();

      return time < lastTimeLocationRetrieved + tenMinutes;
    },
    _getCurrentTimeStamp: function _getCurrentTimeStamp() {
      return Math.round(new Date().getTime() / 1000);
    },
    _icon: function _icon(icon) {
      return L.icon({
        iconUrl: this._iconPath(icon)
      });
    },
    _addIcons: function _addIcons(icons, geographicalMap) {
      var _this4 = this;

      var iconsToShow = icons.filter(function (icon) {
        return _this4._hasLatitudeLongitude(icon);
      });
      var visibleIcons = iconsToShow.map(function (icon) {
        var coordinates = [icon.get('latitude'), icon.get('longitude')];

        var iconToShow = _this4._createIcon(icon, coordinates, geographicalMap);

        if (_this4.get('iconsWithPopup').includes(icon.get('id'))) {
          iconToShow.openPopup();
        }

        return iconToShow;
      });
      this.set('visibleIcons', visibleIcons);
    },
    _bindPopup: function _bindPopup(marker, icon) {
      var iconDescription = icon.get('description') || icon.get('iconTitle');
      marker.bindPopup(iconDescription, {
        autoClose: false,
        closeButton: false,
        offset: [20, 10],
        closeOnClick: false,
        popupAnchor: [20, 20],
        autoPan: false
      });
    },
    _coordinatesForMarker: function _coordinatesForMarker(coordinates) {
      var coordinatesForMarker = this._getCurrentLocationCoordinates();

      if ((0, _utils.isEmpty)(coordinatesForMarker)) {
        coordinatesForMarker = coordinates;
      }

      if ((0, _utils.isEmpty)(coordinatesForMarker)) {
        // Fallback to one of geographical midpoints of Europe.
        coordinatesForMarker = this._getCenterCoordinates();
      }

      return coordinatesForMarker;
    },
    _hasLatitudeLongitude: function _hasLatitudeLongitude(item) {
      return (0, _utils.isPresent)(item) && (0, _utils.isPresent)(item.get('latitude')) && (0, _utils.isPresent)(item.get('longitude'));
    },
    _iconPath: function _iconPath(icon) {
      var iconName = icon.get('iconName') || icon;
      var iconFileNameParts = [iconName];
      var isSelected = icon.get('isSelected');
      var isCompleted = icon.get('isCompleted');

      if (isSelected) {
        iconFileNameParts.push('green');
      }

      if (isCompleted) {
        iconFileNameParts.push('completed');
      }

      return this.get('assetMap').resolve("assets/".concat(iconFileNameParts.join('-'), ".png"));
    },
    _iconZIndex: function _iconZIndex(icon) {
      //if no z-index is specified or z-indexes are equal, the map itself will arrange the priority based on latitude
      var zIndexSpecificForIcon = icon.get('zIndex') || 0;
      var isSelected = icon.get('isSelected');
      var isCompleted = icon.get('isCompleted');
      var zIndexBasedOnIconColor = 1200; // on top

      if (isSelected) zIndexBasedOnIconColor = 1500;
      if (isCompleted) zIndexBasedOnIconColor = 1100;
      if (isSelected && isCompleted) zIndexBasedOnIconColor = 1400; // on the bottom

      return zIndexSpecificForIcon + zIndexBasedOnIconColor;
    },
    _iconOpacity: function _iconOpacity(icon) {
      if (icon.get('isCompleted')) return 0.5;
      return 1.0;
    }
  });

  _exports.default = _default;
});