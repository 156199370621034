define("bottomline-execution/controllers/trip-stops/show", ["exports", "@ember/controller", "@ember/object/computed", "@ember/object", "@ember/service", "@ember/utils", "bottomline-execution/config/environment", "bottomline-execution/mixins/app-state", "bottomline-execution/mixins/picture-browser"], function (_exports, _controller, _computed, _object, _service, _utils, _environment, _appState, _pictureBrowser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_appState.default, _pictureBrowser.default, {
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    androidIntentsAtx: (0, _service.inject)(),
    i18n: (0, _service.inject)(),
    router: (0, _service.inject)(),
    f7: (0, _service.inject)('framework7'),
    online: (0, _computed.alias)('appState.online'),
    offline: (0, _computed.alias)('appState.offline'),
    enableTruckCompartmentFilter: (0, _object.computed)(function () {
      return this.get('model.truckInventories').length > 1;
    }),
    enableTrailerCompartmentFilter: (0, _object.computed)(function () {
      return this.get('model.trailerInventories').length > 1;
    }),
    showExternalLink: (0, _computed.notEmpty)('truckSettings.external_reporting_url'),
    showTruckCompartments: (0, _object.computed)(function () {
      return (0, _utils.isPresent)(this.get('model.truckInventories'));
    }),
    showTrailerCompartments: (0, _object.computed)(function () {
      return (0, _utils.isPresent)(this.get('model.trailerInventories'));
    }),
    _confirmTripStop: function _confirmTripStop() {
      var _this = this;

      var model = this.get('model');
      this.get('f7').showPreloader();
      return model.confirm().then(function () {
        console.log('Trip stop confirmed');
        console.log('Preparing transition');

        if (_environment.default.APP.target === 'atx') {
          _this.get('androidIntentsAtx').stop(model.get('ActivityCode'));
        }

        console.log('Execute transition');

        _this.get('f7').hidePreloader();

        if (model.get('isPostAuthorizationRequired')) {
          _this.router.transitionTo('trip-stops.authorize-delivery', model.get('TripID'), model.id);
        } else {
          _this.router.transitionTo('trips');
        }

        return false;
      }).finally(function (_) {
        return _this.set('isProcessingConfirmAction', false);
      });
    },
    actions: {
      confirm: function confirm() {
        this.set('isProcessingConfirmAction', true);

        this._confirmTripStop();
      },
      cancel: function cancel() {
        this.get('model').cancel();

        if (_environment.default.APP.target === 'atx') {
          this.get('androidIntentsAtx').stop(this.get('model.ActivityCode'));
        }

        this.router.transitionTo('trips');
      }
    }
  });

  _exports.default = _default;
});